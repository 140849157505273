<template>
  <DialogContainerTest theme="file-storage">
    <FileStorage />
  </DialogContainerTest>
</template>

<script>
import FileStorage from "@component/FileStorage/FileStorage";
import DialogContainerTest from "@test/page/DialogContainerTest";
export default {
  components: {
    DialogContainerTest,
    FileStorage
  }
}  
</script>